import { FormControl, styled, TextField } from "@mui/material";
import { forwardRef, PropsWithChildren } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface TextFormFieldProps {
  label?: string;
  name: string;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  multiline?: boolean;
  onValueChange?: (value: string) => void;
  isDisabled?: boolean;
}

const TextFormFieldContainer = styled("div")`
  flex: 1 1 auto;
  min-width: 150px;
`;

export const TextFormField = forwardRef(
  (
    {
      label,
      name,
      rows,
      minRows,
      maxRows,
      multiline = false,
      onValueChange,
      isDisabled,
    }: PropsWithChildren<TextFormFieldProps>,
    ref
  ) => {
    const { control } = useFormContext();

    return (
      <TextFormFieldContainer data-testid="TextFormField">
        <FormControl sx={{ height: "100%" }} fullWidth>
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange, ref: fieldRef, value, ...field },
            }) => (
              <TextField
                multiline={multiline}
                fullWidth
                disabled={isDisabled}
                value={(value as string) ?? ""}
                sx={{
                  height: "100%",
                }}
                InputProps={{
                  style: {
                    height: "100%",
                    borderRadius: "5px",
                  },
                }}
                rows={rows}
                minRows={minRows}
                maxRows={maxRows}
                inputRef={ref ?? fieldRef}
                label={label}
                onChange={(event) => {
                  onChange(event.target.value);
                  onValueChange && onValueChange(event.target.value);
                }}
                {...field}
              />
            )}
          />
        </FormControl>
      </TextFormFieldContainer>
    );
  }
);
