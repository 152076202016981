/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  UnfoldMore,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  styled,
  SxProps,
  Table as MuiTable,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DefaultContainerComponent,
  PimoClassComponent,
  PimoReactComponent,
  TableDefinition,
} from "@pimo/pimo-app-builder";

import {
  PimoBaseTableEventNames,
  PimoBaseTableEventPayload,
  PimoBaseTableProps,
  PimoBaseTableRowProps,
} from "../../types";

export const PimoBaseTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.secondary.main,
}));
export const PimoBaseTableHeader = styled(TableHead)(() => ({
  borderBottom: "1px solid",
}));
export const PimoBaseTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.secondary.main,
  maxHeight: "55px",
}));

class PimoBaseTable<
  Definition extends TableDefinition,
> extends PimoClassComponent<
  any,
  PimoBaseTableEventNames,
  PimoBaseTableEventPayload
> {
  constructor(private componentsInColumns: Definition) {
    super();
  }

  render(): PimoReactComponent<
    any,
    PimoBaseTableEventNames,
    PimoBaseTableEventPayload
  > {
    return ({
      container = DefaultContainerComponent,
      containerProps,
      data,
      tableHeaderEntries,
      fireEvent,
    }: {
      data: {
        columnProps: PimoBaseTable<Definition>["componentsInColumns"];
        rowProps: PimoBaseTableRowProps;
      }[];
      containerProps?: React.ComponentProps<typeof container>;
      fireEvent?: (
        eventName: PimoBaseTableEventNames,
        payload: PimoBaseTableEventPayload
      ) => void;
    } & PimoBaseTableProps) => {
      const Container = container;

      return (
        <Container
          sx={{ boxShadow: "none", borderRadius: "12px" }}
          fireEvent={fireEvent}
          {...containerProps}
        >
          <MuiTable>
            <PimoBaseTableHeader>
              <PimoBaseTableRow>
                {tableHeaderEntries
                  ?.filter((item) => {
                    return !item.isHidden;
                  })
                  .map(
                    (
                      { label, fieldName, sortDirection, isSortable, sx },
                      i
                    ) => (
                      <PimoBaseTableCell
                        sx={{ cursor: isSortable ? "pointer" : "default" }}
                        key={i}
                        onClick={() =>
                          fireEvent?.("sort", {
                            sort: {
                              key: fieldName,
                            },
                          })
                        }
                      >
                        <Box
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            gap: 1.5,
                            textWrapMode: "nowrap",
                            alignItems: "center",
                            display: "flex",
                            ...sx,
                          }}
                        >
                          {label}

                          {isSortable &&
                            (sortDirection ? (
                              sortDirection === "ASC" ? (
                                <ExpandLess
                                  sx={{
                                    fontSize: "1rem",
                                  }}
                                />
                              ) : (
                                <ExpandMore
                                  sx={{
                                    fontSize: "1rem",
                                  }}
                                />
                              )
                            ) : (
                              <UnfoldMore
                                sx={{
                                  fontSize: "1rem",
                                }}
                              />
                            ))}
                        </Box>
                      </PimoBaseTableCell>
                    )
                  )}
              </PimoBaseTableRow>
            </PimoBaseTableHeader>
            <tbody>
              {data.map(({ columnProps, rowProps }, index) => {
                const { cardProps, ...props } = rowProps;

                return (
                  <PimoBaseTableRow
                    {...props}
                    key={index}
                    sx={{
                      cursor: props.onClick ? "pointer" : "default",
                      ...(cardProps?.sx ?? {}),
                    }}
                  >
                    {columnProps.map(
                      (
                        props: {
                          component: PimoReactComponent<any, any>;
                          isHidden?: boolean;
                          cardProps?: {
                            sx?: SxProps;
                          };
                        },
                        index
                      ) => {
                        const ComponentForColumn =
                          this.componentsInColumns[index]?.component ??
                          (() => <></>);

                        if (props.isHidden) {
                          return;
                        }

                        return (
                          <PimoBaseTableCell key={index}>
                            <ComponentForColumn
                              {...props}
                              // TODO: Set padding to be 0 by default in every table cell, so this override can be deleted
                              cardProps={{
                                sx: {
                                  ...props?.cardProps?.sx,
                                  /** Reset the padding applied by `OverviewTableCell` */
                                  padding: 0,
                                },
                              }}
                            />
                          </PimoBaseTableCell>
                        );
                      }
                    )}

                    {props.onEditClick && (
                      <PimoBaseTableCell>
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="edit table entry"
                          onClick={(event) => {
                            event.stopPropagation();
                            props.onEditClick?.(event);
                          }}
                        >
                          <Edit sx={{ fontSize: "1.25rem" }} />
                        </IconButton>
                      </PimoBaseTableCell>
                    )}
                    {props.onDeleteClick && (
                      <PimoBaseTableCell>
                        <IconButton
                          size="small"
                          color="error"
                          aria-label="delete table entry"
                          onClick={(event) => {
                            event.stopPropagation();
                            props.onDeleteClick?.(event);
                          }}
                        >
                          <Delete sx={{ fontSize: "1.25rem" }} />
                        </IconButton>
                      </PimoBaseTableCell>
                    )}
                  </PimoBaseTableRow>
                );
              })}
            </tbody>
          </MuiTable>
        </Container>
      );
    };
  }
}
export default PimoBaseTable;
