import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard, type TitleCardProps } from "@pimo/pimo-components";
import { Currency } from "crq-types";

import {
  CRQTitleCardEndSlot,
  type CRQTitleCardEndSlotProps,
} from "./crq-title-card-end-slot";

export type CRQTitleCardProps = TitleCardProps &
  Omit<CRQTitleCardEndSlotProps, `on${string}`>;
export type CRQTitleCardEvent =
  | "edit-button:click"
  | "run-button:click"
  | "select:change";
export type CRQTitleCardEventPayload = Currency;

export const CRQTitleCard: PimoReactComponent<
  CRQTitleCardProps,
  CRQTitleCardEvent,
  CRQTitleCardEventPayload
> = ({
  currencies,
  currency,
  fireEvent,
  questionnaireSubmittedAt,
  questionnaireSubmittedBy,
  status,
  externalLink,
  active,
  ...props
}) => {
  return (
    <TitleCard
      {...props}
      endSlot={
        <CRQTitleCardEndSlot
          currencies={currencies}
          currency={currency}
          externalLink={externalLink}
          onEditClick={() => fireEvent?.("edit-button:click")}
          onRunClick={() => fireEvent?.("run-button:click")}
          onSelectChange={(e) =>
            fireEvent?.("select:change", e.target.value as Currency)
          }
          questionnaireSubmittedAt={questionnaireSubmittedAt}
          questionnaireSubmittedBy={questionnaireSubmittedBy}
          status={status}
          active={active}
        />
      }
    />
  );
};
