import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlProps,
  Radio,
  RadioGroup,
  RadioProps,
  styled,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface SingleRadioStatusIndicatorFieldProps {
  /** name/reference for getting the value */
  name: string;
  /** all possible radio button values */
  values: { value: string; label: string; color?: string }[];
  /** custom styling for the object */
  formProps?: FormControlProps;
  /** radio button props */
  radioProps?: RadioProps;
  /** on change listener */
  handleChange?: (value: string) => void;
}

const StatusFormControl = styled(FormControl)(() => ({
  display: "flex",
  flexDirection: "row",
}));

/** form field with radio buttons to set the status */
export const SingleRadioStatusIndicatorFormField: FC<
  SingleRadioStatusIndicatorFieldProps
> = ({ name, values, handleChange, formProps = {}, radioProps = {} }) => {
  const { control } = useFormContext();
  const theme = useTheme();

  return (
    <Box sx={{ ...formProps.sx, pr: 3 }} data-testid="StatusIndicatorFormField">
      <StatusFormControl>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <RadioGroup
              sx={{ gap: 0.5, minWidth: 100 }}
              {...field}
              aria-labelledby={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value);
                handleChange &&
                  handleChange((event.target as HTMLInputElement).value);
              }}
            >
              {values.map((val) => (
                <FormControlLabel
                  value={val.value}
                  labelPlacement="start"
                  key={val.value}
                  disabled={radioProps.disabled}
                  sx={{ gap: 3 }}
                  control={
                    <Radio
                      value={val.value ?? ""}
                      key={val.value}
                      sx={{
                        ...radioProps.sx,
                        color: theme.palette.primary.main,
                        height: 18,
                        width: 18,
                        "&.Mui-checked": {
                          color: val.color ?? theme.palette.primary.main,
                          backgroundColor:
                            val.color ?? theme.palette.primary.main,
                        },
                      }}
                    />
                  }
                  label={val.label}
                />
              ))}
            </RadioGroup>
          )}
        />
      </StatusFormControl>
    </Box>
  );
};

export default SingleRadioStatusIndicatorFormField;
