/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import { Theme } from "@mui/system";
import { isValid } from "@pimo/pimo-plumber";
import {
  createContext,
  type PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {
  createBrowserRouter,
  NavigateOptions,
  RouterProvider,
} from "react-router-dom";

import { defaultTheme } from "../themes";
import { useAppState } from "./app-state-context";
import { DefaultLayout } from "./default-layout";
import { EventEmitter } from "./event-emitter";
import type { Layout } from "./layout";
import type { Plugin } from "./plugin";
import type { Route, RouteParameters } from "./route";
import { Router } from "./router";
import { View } from "./view";

/** `State`, that is mandatory to *all* applications, is considered `Universal` */
export type UniversalAppState = {
  isLoading: boolean;
};

/** helper function to ensure that the given state matches a certain type */
export function composeInitialState<AppState>(state: AppState): AppState {
  return state;
}

export const RouterContext = createContext<ReturnType<
  typeof createBrowserRouter
> | null>(null);

type AppEventType =
  | "init"
  | "state:changed"
  | "router:navigated-to-path"
  | "router:route-loaded";

/**
 * The main class of the Pimo App Builder.
 * It is used to create a new app, register plugins, manage state and render the app.
 */
export class App<AppState extends UniversalAppState> extends EventEmitter<
  AppEventType,
  { path?: string }
> {
  private views: View<AppState, any>[] = [];
  private theme?: Theme;
  private router = new Router<AppState>();
  private plugins: Plugin<AppState, unknown, string>[] = [];

  /** generate a new app, that has a type and initial state */
  static create<AppState extends UniversalAppState>(
    initialState: AppState,
    appKey: string
  ): App<AppState> {
    return new App<AppState>(initialState, appKey);
  }

  private constructor(
    private initialState: AppState,
    appKey: string
  ) {
    super();

    // we don't want to block the entire app for the license check, so we'll
    // just let the promise float

    isValid(appKey).then((valid) => !valid && this.triggerLicenseKillSwitch());

    useAppState.setState(this.initialState);

    this.router.on("navigated-to-path", ({ payload }) =>
      this.fireEvent("router:navigated-to-path", { path: payload?.path })
    );

    this.router.on("route-loaded", ({ payload }) =>
      this.fireEvent("router:route-loaded", { path: payload?.path })
    );

    window.addEventListener("keyup", (event) => {
      if (
        // the shortcuts for win/mac are interpreted slightly differently.
        // to ensure that the shortcuts work on both platforms, we have to check for both.
        // to do so, the following website was used:
        // https://www.toptal.com/developers/keycode
        (event.altKey && event.code === "KeyS") ||
        (event.altKey && event.key === "s")
      ) {
        console.log("App state: ", useAppState.getState());
      }
    });
  }

  /** create a view (page), store it, and return a reference */
  public createView<LayoutProps>({
    name,
    layout = new DefaultLayout() as Layout<LayoutProps>,
  }: {
    name: string;
    layout?: Layout<LayoutProps>;
  }): View<AppState, LayoutProps> {
    const view = new View<AppState, LayoutProps>(name, layout);
    this.views.push(view);
    return view;
  }

  /** glueing together all the pieces to prepare for render */
  private withAppStateContext = ({ children }: PropsWithChildren) => {
    const appState = useAppState() as AppState;

    // `routerDebounced` holds the router that is to be used in the app.
    const [routerDebounced, setRouterDebounced] = useState<
      ReturnType<typeof createBrowserRouter>
    >(this.router.router);

    // `router` holds the router that is the latest, up-to-date version and
    // will become the next `routerDebounced` after it is stable for one tick.
    const [router, setRouter] = useState<
      ReturnType<typeof createBrowserRouter>
    >(this.router.router);

    useEffect(() => {
      this.router.on("new-router-created", () => {
        setRouter(this.router.router);
      });
    }, []);

    // debounce router changes so when a lot of changes happen at the same time, we don't
    // force a re-render for every single change, but only when all changes are done
    useEffect(() => {
      const timeoutHandle = setTimeout(() => {
        setRouterDebounced(router);
      }, 0);
      return () => clearTimeout(timeoutHandle);
    }, [router]);

    // the `RouterContext` is just there to force a re-render when the router
    // has changed. Its value is not actually used anywhere, but it is directly
    // retrieved in `render` from `this.router.router`.
    return (
      <RouterContext.Provider value={routerDebounced}>
        <ThemeProvider theme={this.theme ?? defaultTheme}>
          <Box
            sx={{
              alignItems: "center",
              height: "100vh",
              justifyContent: "center",
              width: "100vw",
              position: "fixed",
              background: "#fff",
              zIndex: 10000,
              display: appState.isLoading ? "flex" : "none",
            }}
          >
            <CircularProgress />
          </Box>
          <Box sx={{ visibility: appState.isLoading ? "hidden" : "visible" }}>
            {children}
          </Box>
        </ThemeProvider>
      </RouterContext.Provider>
    );
  };

  /**
   * @deprecated - will be removed in a future version for the clearer `replaceAppState` and `patchAppState` functions.
   * set the state of the app
   **/
  public setAppState(state: AppState | Partial<AppState>) {
    this.patchAppState(state);
  }

  /** replace the app state with a new value. the old app state is _gone_ afterwards. */
  public replaceAppState(state: AppState) {
    useAppState.setState(state, true);
  }

  /** patch a piece of the app state. only that piece is updated, the rest of the state remains */
  public patchAppState(state: AppState | Partial<AppState>) {
    useAppState.setState(state);
    this.fireEvent("state:changed");
  }

  /** get the state of the app */
  public getAppState(): AppState {
    return useAppState.getState() as AppState;
  }

  /** set the theme for all underlying components */
  public setTheme(theme: Theme) {
    this.theme = theme;
  }

  /** create a new route, store it and return a reference */
  createRoute<RoutePathParamKeys extends string = "", LayoutProps = any>(
    routeParameters: RouteParameters<AppState, LayoutProps>
  ): Route<RoutePathParamKeys, AppState, LayoutProps> {
    return this.router.createRoute<LayoutProps, RoutePathParamKeys>(
      routeParameters
    );
  }

  /** create new overlay view (for commonly displayed/shared components) */
  createOverlayView<LayoutProps>({
    name,
    layout = new DefaultLayout() as Layout<LayoutProps>,
  }: {
    name: string;
    layout?: Layout<LayoutProps>;
  }): View<AppState, LayoutProps> {
    return this.router.createOverlayView({ name: name, layout: layout });
  }

  /** (client-side) navigate to a new path */
  navigate(path: string, options?: NavigateOptions) {
    this.router.navigate(path, options);
  }

  /** register a new plugin an invoke its `onRegister` method */
  registerPlugin(plugin?: Plugin<AppState, unknown, string>) {
    if (!plugin) return;

    this.plugins.push(plugin);
    plugin.onRegister(this);
  }

  /** allows to retrieve a plugin by its name */
  getPluginByName<PluginType extends Plugin<AppState, unknown, string>>(
    name: string
  ): PluginType | undefined {
    return this.plugins.find((plugin) => plugin.getName?.() === name) as
      | PluginType
      | undefined;
  }

  /** the pimo app entry point, used to render everything defined so far */
  public render() {
    // fire this event after rendering is done, hence the setTimeout
    setTimeout(() => this.fireEvent("init"));

    return () =>
      this.withAppStateContext({
        children: <RouterProvider router={this.router.router} />,
      });
  }

  /** trigger the license kill switch to protect our ip */
  private triggerLicenseKillSwitch() {
    // conciously totally destroy the DOM
    document.body.innerHTML = "No valid license key.";
  }
}
