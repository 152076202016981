import { Box, styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import { FC } from "react";

interface IdentifierBadgeProps {
  children: number | string;
  /** background color */
  chipColor?: string;
  /** size */
  isLarge?: boolean;
  /** custom width */
  chipWidth?: number;
  /** custom height */
  chipHeight?: number;
  /** Size of the chip text. Options: "normal" (default) or "small" */
  chipTextSize?: "normal" | "small";
}

/** Badge for displaying an ID number */
const SquareBadge = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "color" &&
    prop !== "isLarge" &&
    prop !== "chipWidth" &&
    prop !== "chipHeight" &&
    prop !== "chipTextSize",
})<{
  isLarge?: boolean;
  color?: string;
  chipWidth?: number;
  chipHeight?: number;
  chipTextSize?: "normal" | "small";
}>(({ color, isLarge, chipWidth, chipHeight, chipTextSize }) => ({
  backgroundColor: color ?? blue[500],
  color: "white",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: isLarge ? 7 : 5,
  height: chipHeight ? chipHeight : isLarge ? 30 : "auto",
  minWidth: chipWidth ? chipWidth : isLarge ? 30 : 25,
  borderRadius: 5,
  fontWeight: 500,

  ...(chipTextSize === "small" && {
    fontSize: "14px",
    lineHeight: "18px",
  }),
}));

export const IdentifierBadge: FC<IdentifierBadgeProps> = ({
  children,
  chipColor,
  isLarge,
  chipWidth,
  chipHeight,
  chipTextSize = "normal",
}) => (
  <SquareBadge
    color={chipColor}
    isLarge={isLarge}
    chipWidth={chipWidth}
    chipHeight={chipHeight}
    chipTextSize={chipTextSize}
    data-testid="IdentifierBadge"
  >
    {children}
  </SquareBadge>
);
