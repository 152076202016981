import { QuestionnaireResponse } from "crq-types";

import { validateFormData } from "../form/validate-form-data";

/**
 * Checks if the questionnaire response is valid and returns the draft status.
 * The draft status can be:
 * - "up-to-date": the questionnaire response is valid
 * - "to-be-updated": the questionnaire response is invalid
 * - "unknown": the questionnaire response is null
 *
 * @param questionnaireResponse The questionnaire response to be validated.
 */
export function getDraftStatus(
  questionnaireResponse: QuestionnaireResponse | null
): "up-to-date" | "to-be-updated" | "unknown" {
  if (!questionnaireResponse) {
    return "unknown";
  }

  const errors = validateFormData(questionnaireResponse);
  const hasErrors = Object.keys(errors).length > 0;

  return hasErrors ? "to-be-updated" : "up-to-date";
}
