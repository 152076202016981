/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter } from "./event-emitter";
import type { RouterEventPayload } from "./router";
import type { View } from "./view";

export type RouteParameters<AppState, LayoutProps> = {
  path: string;
  view: View<AppState, LayoutProps>;
  /**
   * should the route be rendered, for example we might want a path to render only for admin users etc
   * `true` by default
   */
  isVisible?: boolean;
  /**
   * should the path be a child of overlay or not i.e: login pages, logout redirects etc
   * `true` by default
   */
  isOverlayChild?: boolean;
  /**
   * should the route render all of it's children when this route gets rendered.
   * this is helpful to create multiple views for the same page
   */
  isRenderingAllChildren?: boolean;
};

export type RouteEventType = "change" | "load";

export class Route<
  RoutePathParamKeys extends string = "",
  AppState = any,
  LayoutProps = any,
> extends EventEmitter<RouteEventType, RouterEventPayload<RoutePathParamKeys>> {
  children: Route<string, AppState, any>[] = [];

  constructor(
    readonly path: string,
    readonly view: View<AppState, LayoutProps>,
    readonly isVisible: boolean,
    readonly isOverlayChild: boolean,
    readonly isRenderingAllChildren: boolean
  ) {
    super();
  }

  createChildRoute<RoutePathParamKeys extends string = "", LayoutProps = any>(
    routeParameters: RouteParameters<AppState, LayoutProps>
  ) {
    const {
      path,
      view,
      isVisible = true,
      isOverlayChild = true,
      isRenderingAllChildren = false,
    } = routeParameters;
    const route = new Route<RoutePathParamKeys, AppState>(
      path,
      view,
      isVisible,
      isOverlayChild,
      isRenderingAllChildren
    );

    this.children.push(route);
    this.fireEvent("change");

    return route;
  }

  fireRouteEvent(
    eventType: RouteEventType,
    payload: RouterEventPayload<RoutePathParamKeys>
  ) {
    this.fireEvent(eventType, payload);
  }
}
